import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import Axios from "../../../../Helper/axiosHelper";

export const fetchQuickBites = createAsyncThunk(
  'food/fetchQuickBites',
  async () => {
    const response = await Axios({
      method: "get",
      url: `/quickbites`,
    })
    // const response = await axios.get('https://flavify-test-caa8d1ec1c7d.herokuapp.com/api/v1/quickbites');
    return response.data.data;
  }
);

// Async thunk to fetch menu
export const fetchMenu = createAsyncThunk(
  'food/fetchMenu',
  async () => {
    const response = await Axios({
      method: "get",
      url: `/menu`,
    })
    // const response = await axios.get('https://flavify-test-caa8d1ec1c7d.herokuapp.com/api/v1/menu');
    return response.data;
  }
);

const initialState = {
  quickBites: [],
  menu: [],
  categories: {},
  loading: false,
};

const foodSlice = createSlice({
  name: 'food',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchQuickBites.fulfilled, (state, action) => {
        state.loading = false;
        state.quickBites = action.payload;
      })
      .addCase(fetchMenu.fulfilled, (state, action) => {
        state.loading = false;
        state.menu = action.payload;
        const items = action.payload.items;
        function getRandomGif(imgGallery) {
          const randomIndex = Math.floor(Math.random() * imgGallery.length);
          return imgGallery[imgGallery]?.url;
        }
        const categories = items.reduce((acc, item) => {
          if (!acc[item.item_subcategory]) {
            acc[item.item_subcategory] = [];
          }
          acc[item.item_subcategory].push(item);
          return acc;
        }, {});
        state.categories = Object.keys(categories).map(category => {
          const categoryItems = categories[category];
          if (categoryItems && categoryItems.length > 0) {
            // Get a random item from the category items
            const randomIndex = Math.floor(Math.random() * categoryItems.length);
            const randomItem = categoryItems[randomIndex];
            return {
              item_name: category,
              url: randomItem.url, // Use the random item's URL
            };
          }
          // const items = categories[category];
          // if (items && items.length > 0) {
          //   return {
          //     item_name: category,
          //     url: items[0].url // Assuming you want the first item's image in each category
          //   };
          // }
          return null;
        }).filter(item => item !== null);
      })
  },
});

export default foodSlice.reducer;

