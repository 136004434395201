import React from 'react'
import './Loder.css'
function Loader() {
  return (
    <div class="loading-state">
    <div class="loading"></div>
    </div>
  )
}

export default Loader