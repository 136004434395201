import React from 'react';
import './Cart.css';
import TableHeaderTitle from '../../CommonComponent/TableTitle/TableHeaderTitle';
import CartItem from './CartItem/CartItem';



function Cart() {
    return (
        <>
            <CartItem />
        </>
    );
}

export default Cart;
