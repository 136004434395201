import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "./Combos.css";
import { Image, Modal } from "react-bootstrap";
import OwlCarousel from "react-owl-carousel";
import { Icon } from "@iconify/react/dist/iconify.js";
import Accordion from "react-bootstrap/Accordion";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import {
  addComboItemToCart,
  addItemToCart,
} from "../../../Pages/CartPage/Cartslice/Cartslice";
import { getGroupedOptionsAndAddOns } from "../../../Helper/Coman";
import { isEmpty } from "lodash";
import { formatIndianCurrency } from "../../../Helper/currencyUtil";

function CombosSlider() {
  const options = {
    margin: 24,
    responsiveClass: true,
    nav: false,
    dots: false,
    smartSpeed: 500,
    autoplay: false,
    loop: true,
    items: 1.5,
  };
  const [show, setShow] = useState(false);
  const [filtereItem, setFilteredItem] = useState([]);
  const [adon, setAdon] = useState([]);
  const [option, setOption] = useState({});
  const [isFilled, setIsFilled] = useState(false);
  const [allCombos, setAllCombos] = useState([]);
  const [adonPrice, setAdonPrice] = useState(0);
  const [optionPrice, setOptionPrice] = useState(0);
  const [activeKeys, setActiveKeys] = useState([]);
  const [expandedItem, setExpandedItem] = useState(null);
  const { comboList } = useSelector((state) => state?.table);
  const { cartItemsList } = useSelector((state) => state?.cart);
  const { menu } = useSelector((state) => state.food);
  const dispatch = useDispatch();

  useEffect(() => {
    const newAdonPrice = Object.values(adon).reduce((acc, addOns) => {
      const totalAddOnPrice = addOns.reduce(
        (sum, addOn) => sum + addOn.price,
        0
      );
      return acc + totalAddOnPrice;
    }, 0);
    const newOptionPrice = Object.values(option).reduce((acc, options) => {
      const totalOptionPrice = Object.values(options).reduce(
        (sum, option) => sum + option.price,
        0
      );
      return acc + totalOptionPrice;
    }, 0);
    setAdonPrice(newAdonPrice);
    setOptionPrice(newOptionPrice);
  }, [adon, option]);

  useEffect(() => {
    if (comboList) {
      setAllCombos(comboList);
    }
  }, [comboList]);

  const handleQuickbiteClick = (combo) => {
    const menuItem = menu.items.find((i) => i.item_id === combo.item_id);
    const { groupedOptions, groupedAddOns } = getGroupedOptionsAndAddOns(
      menu,
      combo.item_id
    );
    const data = {
      ...menuItem,
      addOnsGrouped: groupedAddOns,
      optionsGrouped: groupedOptions,
    };
    return data;
  };

  const handleClose = () => setShow(false);
  const handleShow = async (item) => {
    setShow(true);
    const comboDetails = await item?.items?.map((i) => handleQuickbiteClick(i));
    const data = {
      ...item,
      qty: 1,
      items: comboDetails,
    };
    setFilteredItem(data);
    setOptionPrice(0);
    setAdonPrice(0);
    window.history.pushState({ modal: true }, '');
  };

  // Event handler to toggle the filled state
  const handleIconClick = () => {
    setIsFilled(!isFilled);
  };

  // Handler to increase the count
  const handleAddClick = (item) => {
    setFilteredItem({ ...filtereItem, qty: filtereItem.qty + 1 });
  };

  // Handler to decrease the count
  const handleRemoveClick = (item) => {
    if (filtereItem.qty > 1) {
      setFilteredItem({ ...filtereItem, qty: filtereItem.qty - 1 });
    }
  };

  const calculateTotalPrice = () => {
    const totalPrice = filtereItem.total - filtereItem.discount;
    const totalPriceWithqty =
      (totalPrice + adonPrice + optionPrice) * filtereItem.qty;
    return totalPriceWithqty;
  };

  const handleAdonChange = (e, itemId, addOnId, price) => {
    const isChecked = e.target.checked;
    setAdon((prev) => {
      const itemAddOns = prev[itemId] || [];
      if (isChecked) {
        return {
          ...prev,
          [itemId]: [...itemAddOns, { addon_id: addOnId, price }],
        };
      } else {
        return {
          ...prev,
          [itemId]: itemAddOns.filter((addOn) => addOn.addon_id !== addOnId),
        };
      }
    });
  };

  const handleOptionChange = (e, itemId, optionId, price, groupName) => {
    setOption((prev) => {
      return {
        ...prev,
        [itemId]: {
          ...prev[itemId],
          [groupName]: { option_id: optionId, price: price },
        },
      };
    });
  };

  console.log({ filtereItem, option })
  const handleAddToCart = () => {
    // Ensure that one item is selected from each optionsGrouped
    const allItemsValid = filtereItem.items.every((item) => {
      const { optionsGrouped } = item;
  
      // Retrieve selected options for the current item
      const selectedOptions = option[item.item_id] || {};
  
      // Validate that each group in optionsGrouped has a selected option
      return optionsGrouped.every((group) =>
        group.itemList.some(
          (opt) =>
            selectedOptions[group.groupName] &&
            selectedOptions[group.groupName].option_id === opt.option_id
        )
      );
    });
  
    if (!allItemsValid) {
      toast.error("Please select one option from each group for all items.");
      return;
    }
  
    const cartItemsAdd = filtereItem.items.map((item) => ({
      item_id: item.item_id,
      item_name: item.item_name,
      price: item.price,
      url: item.url,
      diet: item.diet,
      item_description: item?.item_description,
      add_ons: adon[item?.item_id] ? [...adon[item?.item_id]] : [],
      options: option[item?.item_id] ? option[item?.item_id] : {},
    }));
  
    const cartData = {
      combo: "LandingPage",
      qty: 1,
      price: filtereItem.total,
      discount: filtereItem.discount,
      items: cartItemsAdd,
    };
  
    let cartItems = [...cartItemsList] || [];
    const existingItemIndex = cartItems?.findIndex(
      (cartItem) =>
        JSON.stringify(cartItem.items) === JSON.stringify(cartData.items)
    );
  
    if (existingItemIndex !== -1) {
      cartItems[existingItemIndex].qty += filtereItem?.qty;
    } else {
      cartItems.push(cartData);
    }
  
    dispatch(addItemToCart(cartItems));
    sessionStorage.setItem("cartItems", JSON.stringify(cartItems));
    toast.success(`Item added successfully`);
  
    setAdon({});
    setOption({});
    setOptionPrice(0);
    setAdonPrice(0);
    setShow(false);
  };

  const addOnsGroupeds = (
    groupedAddOns,
    adon,
    option,
    itemId,
    handleAdonChange
  ) => {
    if (!groupedAddOns || groupedAddOns.length === 0) return null;

    return (
      <div className="addons-grouped">
        <hr />
        <h5>Add-Ons:</h5>
        {groupedAddOns.map((group, index) => (
          <li key={`addon-group-${index}`} className="combo-option-list">
            <h3>{group.groupName}</h3>
            <ul className="selectvariantmain ">
              {group.itemList.map((addon, addonIndex) => (
                <li key={`addon-${itemId}${addonIndex}`}>
                  <h5>{addon.addon_name}</h5>
                  <label
                    className="custom"
                    htmlFor={`selectaddonoption${itemId}${addon.addon_id}`}
                  >
                    <span className="checkbox-label">₹{addon.price}</span>
                    <input
                      type="checkbox"
                      id={`selectaddonoption${itemId}${addon.addon_id}`}
                      value={addon}
                      onChange={(e) =>
                        handleAdonChange(
                          e,
                          itemId,
                          addon.addon_id,
                          addon.price,
                          index
                        )
                      }
                    />
                    <span className="checkbox-indicator"></span>
                  </label>
                </li>
              ))}
            </ul>
          </li>
        ))}
      </div>
    );
  };

  const optionsGroupeds = (
    groupedOptions,
    adon,
    option,
    itemId,
    handleOptionChange
  ) => {
    if (!groupedOptions || groupedOptions.length === 0) return null;

    return (
      <div className="options-grouped">
        <hr />
        <h5>Options:</h5>
        {groupedOptions?.map((group, index) => (
          <li key={`option-group-${index}`} className="combo-option-list">
            <h3>{group.groupName}</h3>
            <ul className="selectvariantmain">
              {group.itemList.map((opt, optionIndex) => (
                <li key={`option-${opt.option_id}`}>
                  <h5>{opt.option_name}</h5>
                  <label
                    className="custom"
                    htmlFor={`selectaddonoptionMeat${opt.option_id}`}
                  >
                    <span className="checkbox-label">₹{opt.price}</span>
                    <input
                      type="radio"
                      id={`selectaddonoptionMeat${opt.option_id}`}
                      value={opt}
                      name={`option-${itemId}-${group.groupName}`}
                      onChange={(e) =>
                        handleOptionChange(
                          e,
                          itemId,
                          opt.option_id,
                          opt.price,
                          group.groupName
                        )
                      }
                      checked={
                        !isEmpty(option) && option[itemId]
                          ? option[itemId][group.groupName]?.option_id ===
                            opt.option_id
                          : false
                      }
                    />
                    <span className="checkbox-indicator"></span>
                  </label>
                </li>
              ))}
            </ul>
          </li>
        ))}
      </div>
    );
  };

  const handleToggleAccordion = (key) => {
    if (activeKeys.includes(key)) {
      setActiveKeys(activeKeys.filter((k) => k !== key));
    } else {
      setActiveKeys([...activeKeys, key]);
    }
  };

  useEffect(() => {
    if (filtereItem?.items) {
      setActiveKeys(filtereItem.items.map((_, index) => index.toString()));
    }
  }, [filtereItem]);

  const handleExpandToggle = (index) => {
    setExpandedItem(expandedItem === index ? null : index); // Collapse if same index
  };

  // const navigate = useNavigate();
  // const location = useLocation();

  useEffect(() => {
    const handlePopState = (event) => {
        if (show) {
            setShow(false)
            event.preventDefault(); // Prevent the default back navigation
        }
    };

    // Add event listener for popstate
    window.addEventListener('popstate', handlePopState);

    // Cleanup the event listener on component unmount
    return () => {
        window.removeEventListener('popstate', handlePopState);
    };
}, [show]);

  return (
    <div className="Combomain">
      {allCombos?.length > 0 && (
        <OwlCarousel className="owl-theme mb-3" {...options}>
          {comboList?.map((item, index) => (
            <div className="item" key={index} onClick={() => handleShow(item)}>
              <div className="combodetail combo-item-list">
                <ul className="saladimgs gap-1 mb-0">
                  {item?.items?.map((i, ind) => (
                    <li key={ind}>
                      <Image
                        src={i?.url ? i?.url : "Images/combo1.png"}
                      ></Image>
                    </li>
                  ))}
                </ul>
                <div className="combosubdetail">
                  <div className="offertab">
                    <span className="bluetag">₹ {item?.discount} OFF</span>
                    <i>
                      <Image
                        src={
                          item.diet === "N"
                            ? "/Images/nonveg.svg"
                            : item.diet === "V"
                            ? "/Images/veg.svg"
                            : "/Images/egg.svg"
                        }
                        alt="Veg"
                      ></Image>
                    </i>
                  </div>
                  <h3>{item?.items.map((i) => i?.item_name).join(" + ")}</h3>
                  <div className="comboprice d-flex">
                    <p>
                      ₹{formatIndianCurrency(item?.total - item?.discount)}{" "}
                      <del>₹{formatIndianCurrency(item?.total)} </del>
                    </p>
                    <Link onClick={() => handleShow(item)}>
                      View Combo{" "}
                      <Icon
                        icon="teenyicons:right-small-outline"
                        width="16px"
                        height="16px"
                      />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </OwlCarousel>
      )}
      <Modal show={show} onHide={handleClose} className="singleitem combomodal">
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div className="scrollll">
            <div className="guestselectmodalmain pt-0">
              <ul className="saladimgs">
                {filtereItem?.items?.map((i, ind) => (
                  <li key={ind} className="combo-images">
                    <Image src={i?.url ? i?.url : "Images/combo1.png"}></Image>
                  </li>
                ))}
              </ul>
              <div className="ratingmain">
                <ul className="rating">
                  <li>
                    <Icon icon="twemoji:star" width="16px" height="16px" />
                  </li>
                  <li>
                    <Icon icon="twemoji:star" width="16px" height="16px" />
                  </li>
                  <li>
                    <Icon icon="twemoji:star" width="16px" height="16px" />
                  </li>
                  <li>
                    <Icon icon="twemoji:star" width="16px" height="16px" />
                  </li>
                  <li>
                    <Icon icon="twemoji:star" width="16px" height="16px" />
                  </li>
                </ul>
                <span>
                  <Image
                    src={
                      filtereItem.diet === "N"
                        ? "/Images/nonveg.svg"
                        : filtereItem.diet === "V"
                        ? "/Images/veg.svg"
                        : "/Images/egg.svg"
                    }
                    alt="Veg"
                  ></Image>
                </span>
              </div>
              <div className="itemtitle">
                <h3>
                  {filtereItem?.items?.map((i) => i?.item_name).join(" + ")}{" "}
                  <span onClick={handleIconClick} style={{ cursor: "pointer" }}>
                    {isFilled ? (
                      <Icon
                        icon="ph:heart-fill"
                        width="24px"
                        height="24px"
                        style={{ color: "red" }}
                      />
                    ) : (
                      <Icon
                        icon="ph:heart"
                        width="24px"
                        height="24px"
                        style={{ color: "black" }}
                      />
                    )}
                  </span>
                </h3>
                <p>
                  Save Rs.{formatIndianCurrency(filtereItem?.discount)} on this
                  combo
                </p>
              </div>
            </div>
            <Accordion activeKey={activeKeys}>
              {filtereItem?.items?.map((mainitem, mainindex) => (
                <Accordion.Item eventKey={mainindex.toString()} key={mainindex}>
                  <Accordion.Header
                    onClick={() => handleToggleAccordion(mainindex.toString())}
                  >
                    {mainitem?.item_name}
                  </Accordion.Header>
                  <Accordion.Body>
                    <div className="select-variant-container">
                      {/* <p className='descrioton'>{mainitem.item_description}</p> */}
                      <p className="descrioton">
                        {expandedItem === mainindex
                          ? mainitem.item_description || ""
                          : mainitem.item_description &&
                            mainitem.item_description.length > 20
                          ? `${mainitem.item_description.substring(0, 50)}`
                          : mainitem.item_description || ""}
                      </p>
                      {mainitem.item_description ? (
                        <Link
                          className="readmore"
                          onClick={() => handleExpandToggle(mainindex)}
                        >
                          {expandedItem === mainindex
                            ? "Read Less"
                            : "Read More..."}
                        </Link>
                      ) : (
                        ""
                      )}
                      {mainitem.addOnsGrouped.length > 0 && (
                        <div className="selectvariant">
                          <ul className="selectvariantGroup">
                            {addOnsGroupeds(
                              mainitem.addOnsGrouped,
                              adon,
                              option,
                              mainitem.item_id,
                              handleAdonChange
                            )}
                          </ul>
                        </div>
                      )}
                      {mainitem.optionsGrouped.length > 0 && (
                        <div className="selectvariant">
                          <ul className="selectvariantGroup">
                            {optionsGroupeds(
                              mainitem.optionsGrouped,
                              adon,
                              option,
                              mainitem.item_id,
                              handleOptionChange
                            )}
                          </ul>
                        </div>
                      )}
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              ))}
            </Accordion>
          </div>

          <div className="additem">
            <div
              className="addremoveitem"
              style={{ display: "flex", alignItems: "center" }}
            >
              <span
                onClick={() => handleRemoveClick(filtereItem)}
                style={{ cursor: "pointer" }}
              >
                <Icon icon="ri:subtract-fill" width="24px" height="24px" />
              </span>
              <h5 style={{ margin: "0 10px" }}>{filtereItem?.qty}</h5>
              <span
                onClick={() => handleAddClick(filtereItem)}
                style={{ cursor: "pointer" }}
              >
                <Icon icon="ic:round-plus" width="24px" height="24px" />
              </span>
            </div>
            <Link
              className="btngreen continue"
              to="#"
              onClick={handleAddToCart}
            >
              Add Item - ₹{formatIndianCurrency(calculateTotalPrice())}
            </Link>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default CombosSlider;
