import { Icon } from "@iconify/react/dist/iconify.js";
import React, { useEffect, useState } from "react";
import { Accordion, Image } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { addItemToCart } from "../../../../../Pages/CartPage/Cartslice/Cartslice";
import { isEmpty } from "lodash";
function Modals({
  item,
  show,
  onHide,
  setCartItems,
  cartIndex,
}) {
  const [filtereddata, setFiltereddata] = useState([]);
  const [expandedItem, setExpandedItem] = useState(null);
  const [activeKeys, setActiveKeys] = useState([]);
  const [isFilled, setIsFilled] = useState(false);
  const { cartItemsList } = useSelector(state => state.cart)
  const dispatch = useDispatch();

  const calculateAddonAndOptionsPrice = (basePrice) => {
    let totalOptionPrice = 0;
    let totalAddonsPrice = 0;
    basePrice.items?.forEach((item) => {
      item.add_ons.forEach((option) => {
        totalAddonsPrice += option.price;
      });
    });

    basePrice.items?.forEach((item) => {
      Object.values(item.options).forEach((option) => {
        totalOptionPrice += option.price;
      });
    });

    return { totalOptionPrice, totalAddonsPrice };
  };
  const calculateItemPrice = () => {
    if (!isEmpty(filtereddata)) {
      const basePrice = { ...filtereddata };
      const { totalOptionPrice, totalAddonsPrice } =
        calculateAddonAndOptionsPrice(basePrice);
      const totalPrice =
        (basePrice.price +
          totalAddonsPrice +
          totalOptionPrice -
          filtereddata?.discount) *
        filtereddata?.qty;
      return totalPrice;
    } else {
      return 0;
    }
  };

  const handleAddToCart = (itemId, itemCombo) => {
    const cartItems = [...cartItemsList] || [];
    const existingItemIndex = cartItems.findIndex(
      (item) => item.item_id === itemId
    );
  
    // Iterate over each item in filtereddata and check selected options
    const allItemsValid = filtereddata.items.every((filteredItem) => {
      const comboItem = itemCombo.items.find(item => item.item_id === filteredItem.item_id);
      if (!comboItem) return false;
  
      const { optionsGrouped } = comboItem;
      const { options } = filteredItem;
  
      // Check if every options group has a corresponding selection in the item's options
      return optionsGrouped.every((group) =>
        group.itemList.some(
          (option) =>
            options[group.groupName] &&
            options[group.groupName].option_id === option.option_id
        )
      );
    });
  
    if (!allItemsValid) {
      toast.error("Please select one option from each group for all items.");
      return;
    }
  
    if (existingItemIndex !== -1) {
      cartItems[existingItemIndex] = { ...filtereddata, item_id: itemId };
    } else {
      cartItems.push({ ...filtereddata, item_id: itemId });
    }
  
    dispatch(addItemToCart(cartItems));
    sessionStorage.setItem("cartItems", JSON.stringify(cartItems));
    setCartItems(cartItems);
    onHide();
    toast.success(`Item added successfully`);
  };
console.log({ item, filtereddata     })
const handleAdonChange = (e, index, addon) => {
  const isChecked = e.target.checked;

  console.log({ index })

  // Create a deep copy of filtereddata to avoid mutating the state directly
  const tempWorkingHours = filtereddata.items.map((item, i) => {
    if (i === index) {
      return {
        ...item,
        add_ons: isChecked
          ? [...item.add_ons, { addon_id: addon.addon_id, price: addon.price }]
          : item.add_ons.filter(ad => ad.addon_id !== addon.addon_id)
      };
    }
    return item;
  });

  // Update the state with the modified copy
  setFiltereddata((prev) => ({
    ...prev,
    items: tempWorkingHours,
  }));
};


  const handleOptionChange = (e, index, opt, groupName) => {
    const isChecked = e.target.checked;
    let tempWorkingHours = JSON.parse(JSON.stringify(filtereddata.items));
    if (isChecked) {
        console.log({tempWorkingHours, i: tempWorkingHours[index], groupName })
      tempWorkingHours[index]['options'][groupName] = {
        option_id: opt.option_id,
        price: opt.price,
      };
      setFiltereddata((prev) => ({
        ...prev,    
        items: tempWorkingHours,
      }));
    }
  };

  const handleAddClick = () => {
    if (item) {
      setFiltereddata((prev) => ({
        ...prev,
        qty: prev.qty + 1,
      }));
    }
  };

  const handleRemoveClick = () => {
    if (filtereddata?.qty > 1) {
      setFiltereddata((prev) => ({
        ...prev,
        qty: prev.qty - 1,
      }));
    }
  };

  useEffect(() => {
    if (item) {
      const cartItems = [...cartItemsList] || [];
      const data = Array.isArray(cartItems)
        ? cartItems.filter((cartItem, i) => i === cartIndex)
        : [];
        console.log({ data })
      if (data.length > 0) {
        setFiltereddata(data[0]);
      } else {
        setFiltereddata([]);
      }
    }
  }, [item, cartIndex]);
  const handleExpandToggle = (index) => {
    setExpandedItem(expandedItem === index ? null : index);
  };

  const addOnsGroupeds = (
    groupedAddOns,
    mainitem,
    mainindex
  ) => {
    if (!groupedAddOns || groupedAddOns.length === 0) return null;

    // console.log({ mainindex })
    return (
      <div className="addons-grouped">
        <hr />
        <h5>Add-Ons:</h5>
        {groupedAddOns.map((group, index) => (
          <li key={`addon-group-${index}`} className="combo-option-list">
            <h3>{group.groupName}</h3>
            <ul className="selectvariantmain ">
              {group.itemList.map((addon, addonIndex) => (
                <li key={`addon-${mainitem.item_id}${addonIndex}`}>
                  <h5>{addon.addon_name}</h5>
                  <label
                    className="custom-checkbox"
                    htmlFor={`selectaddonoption${mainitem.item_id}${addon.addon_id}`}
                  >
                    <span className="checkbox-label">₹{addon.price}</span>
                    <input
                      type="checkbox"
                      id={`selectaddonoption${mainitem.item_id}${addon.addon_id}`}
                      value={addon}
                      onChange={(e) => {handleAdonChange(e, mainindex, addon); console.log({ mainindex })}}
                      checked={
                        !isEmpty(filtereddata) &&
                        filtereddata.items.some((item) =>
                          item.add_ons?.some(
                            (item) => item.addon_id === addon.addon_id
                          )
                        )
                      }
                    />
                    <span className="checkbox-indicator"></span>
                  </label>
                </li>
              ))}
            </ul>
          </li>
        ))}
      </div>
    );
  };
  const optionsGroupeds = (
    groupedOptions,
    mainitem,
    handleOptionChange,
    mainindex
  ) => {
    if (!groupedOptions || groupedOptions.length === 0) return null;

    return (
      <div className="options-grouped">
        <hr />
        <h5>Options:</h5>
        {groupedOptions?.map((group, index) => (
          <li key={`option-group-${index}`} className="combo-option-list">
            <h3>{group.groupName}</h3>
            <ul className="selectvariantmain">
              {group.itemList.map((option, optionIndex) => (
                <li key={`option-${option.option_id}`} className="">
                  <h5>{option.option_name}</h5>
                  <label
                    className="custom"
                    htmlFor={`selectaddonoptionMeat${option.option_id}`}
                  >
                    <span className="checkbox-label">₹{option.price}</span>
                    <input
                      type="radio"
                      id={`selectaddonoptionMeat${option.option_id}`}
                      name={`option-${mainitem.item_name}-${group.groupName}`}
                      onChange={(e) =>
                        handleOptionChange(
                          e,
                          mainindex,
                          option,
                          group.groupName
                        )
                      }
                      checked={
                        !isEmpty(filtereddata) &&
                        filtereddata?.items[mainindex]?.options[group.groupName]
                          ?.option_id === option.option_id
                      }
                    />
                    <span className="checkbox-indicator"></span>
                  </label>
                </li>
              ))}
            </ul>
          </li>
        ))}
      </div>
    );
  };

  const handleToggleAccordion = (key) => {
    if (activeKeys.includes(key)) {
      setActiveKeys(activeKeys.filter((k) => k !== key));
    } else {
      setActiveKeys([...activeKeys, key]);
    }
  };

  useEffect(() => {
    if (item?.items) {
      setActiveKeys(item.items.map((_, index) => index.toString()));
    }
  }, [item]);

  return (
    <>
      <Modal show={show} onHide={onHide} className="singleitem">
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div className="scrollll">
            <div className="guestselectmodalmain pt-0">
              <ul className="saladimgs">
                {item?.items?.map((i, ind) => (
                  <li key={ind} className={i?.url}>
                    <Image src={i?.url ? i?.url : "Images/combo1.png"}></Image>
                  </li>
                ))}
                {/* <li className='w-100'><Image src='Images/selectedvarient.png'></Image></li> */}
              </ul>
              <div className="ratingmain">
                <ul className="rating">
                  <li>
                    <Icon icon="twemoji:star" width="16px" height="16px" />
                  </li>
                  <li>
                    <Icon icon="twemoji:star" width="16px" height="16px" />
                  </li>
                  <li>
                    <Icon icon="twemoji:star" width="16px" height="16px" />
                  </li>
                  <li>
                    <Icon icon="twemoji:star" width="16px" height="16px" />
                  </li>
                  <li>
                    <Icon icon="twemoji:star" width="16px" height="16px" />
                  </li>
                </ul>
                {item?.items?.length > 0 && (
                  <span>
                    <Image
                      src={
                        item?.items[0].diet === "N"
                          ? "/Images/nonveg.svg"
                          : item?.items[0].diet === "V"
                          ? "/Images/veg.svg"
                          : "/Images/egg.svg"
                      }
                      alt="Veg"
                    ></Image>
                  </span>
                )}
              </div>
              <div className="itemtitle">
                <h3>
                  {item.item_name}
                  {item?.items?.map((i) => i?.item_name).join(" + ")}{" "}
                  <span onClick={() => setIsFilled(!isFilled)} style={{ cursor: "pointer" }}>
                    {isFilled ? (
                      <Icon
                        icon="ph:heart-fill"
                        width="24px"
                        height="24px"
                        style={{ color: "red" }}
                      />
                    ) : (
                      <Icon
                        icon="ph:heart"
                        width="24px"
                        height="24px"
                        style={{ color: "black" }}
                      />
                    )}
                  </span>
                </h3>
                {item.item_description && (
                  <p className="descrioton">
                    {expandedItem === item.item_id
                      ? item.item_description || ""
                      : item.item_description &&
                        item.item_description.length > 20
                      ? `${item.item_description.substring(0, 50)}`
                      : item.item_description || ""}
                  </p>
                )}
                {item.item_description ? (
                  <Link
                    className="readmore"
                    onClick={() => handleExpandToggle(item.item_id)}
                  >
                    {expandedItem === item.item_id
                      ? "Read Less"
                      : "Read More..."}
                  </Link>
                ) : (
                  ""
                )}
              </div>
              <Accordion activeKey={activeKeys}>
                {item?.items?.map((mainitem, mainindex) => (
                  <Accordion.Item
                    eventKey={mainindex.toString()}
                    key={mainindex}
                  >
                    <Accordion.Header
                      onClick={() =>
                        handleToggleAccordion(mainindex.toString())
                      }
                    >
                      {mainitem?.item_name}
                    </Accordion.Header>
                    <Accordion.Body>
                      <div className="select-variant-container">
                        <p className="descrioton">
                          {expandedItem === mainindex
                            ? mainitem.item_description || ""
                            : mainitem.item_description &&
                              mainitem.item_description.length > 20
                            ? `${mainitem.item_description.substring(0, 50)}`
                            : mainitem.item_description || ""}
                        </p>
                        {mainitem.item_description ? (
                          <Link
                            className="readmore"
                            onClick={() => handleExpandToggle(mainindex)}
                          >
                            {expandedItem === mainindex
                              ? "Read Less"
                              : "Read More..."}
                          </Link>
                        ) : (
                          ""
                        )}
                        {mainitem.addOnsGrouped.length > 0 ? (
                          <div className="selectvariant">
                            <div className="selectvarianttitle"></div>
                            {mainitem.addOnsGrouped &&
                            mainitem.addOnsGrouped.length > 0 ? (
                              <ul className="selectvariantGroup">
                                {addOnsGroupeds(
                                  mainitem.addOnsGrouped,
                                  mainitem,
                                  mainindex
                                )}
                              </ul>
                            ) : (
                              ""
                            )}
                          </div>
                        ) : (
                          ""
                        )}

                        {mainitem?.optionsGrouped?.length > 0 ? (
                          <div className="selectvariant">
                            <div className="selectvarianttitle"></div>
                            {mainitem.optionsGrouped &&
                            mainitem.optionsGrouped.length > 0 ? (
                              <ul className="selectvariantGroup">
                                {optionsGroupeds(
                                  mainitem.optionsGrouped,
                                  mainitem,
                                  handleOptionChange,
                                  mainindex
                                )}
                              </ul>
                            ) : (
                              ""
                            )}
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                ))}
              </Accordion>
            </div>
            <div className="select-variant-container">
              <div className="additem">
                <div
                  className="addremoveitem"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <span
                    onClick={item ? handleRemoveClick : null}
                    style={{
                      cursor: item ? "pointer" : "not-allowed",
                      opacity: item ? 1 : 0.5,
                    }}
                  >
                    <Icon icon="ri:subtract-fill" width="24px" height="24px" />
                  </span>
                  <h5 style={{ margin: "0 10px" }}>{filtereddata?.qty}</h5>
                  <span
                    onClick={item ? handleAddClick : null}
                    style={{
                      cursor: item ? "pointer" : "not-allowed",
                      opacity: item ? 1 : 0.5,
                    }}
                  >
                    <Icon icon="ic:round-plus" width="24px" height="24px" />
                  </span>
                </div>
                <Link
                  className="btngreen continue"
                  onClick={() => handleAddToCart(item.item_id, item)}
                >
                  Add Item -₹{calculateItemPrice()}
                </Link>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default Modals;
