import React, { useEffect, useState, useRef } from 'react';
import OwlCarousel from 'react-owl-carousel';
import { Image } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Carousel = ({ isMenu, items, handleQuickbiteClick }) => {
  const [slides, setSlides] = useState([]);
  const isScrolling = useRef(false); // Ref to track if scrolling has occurred

  const settings = {
    dots: false,
    nav:false,
    infinite: false,
    speed: 500,
    slidesToShow: !isMenu ? 4 :  4.5,
    slidesToScroll: !isMenu ? 4 : 4.5,
    centerPadding: 50,
    draggable: isMenu ? true : false,
    beforeChange: () => { isScrolling.current = true; }, // Triggered when slide is changing
    afterChange: () => { isScrolling.current = false } // Reset after scrolling
  };

  useEffect(() => {
    console.log({ items })
    if(items) {
      const sortOrder = [
        "soups & salads",
        "appetizers & mezzes",
        "shawarma, falafel, burgers",
        "turkish pide pizza",
        "kebabs",
        "jumbo group meal combos",
        "pasta",
        "main course",
        "biryani & bread",
        "desserts",
        "beverages",
        "alcoholic",
      ];
      const orderMap = sortOrder.reduce((acc, name, index) => {
        acc[name] = index;
        return acc;
      }, {});
      
      // Sort the items array based on the custom order
      if(isMenu) {
        const sortedItems = [...items].sort((a, b) => orderMap[a.item_name.toLowerCase()] - orderMap[b.item_name.toLowerCase()]);
        setSlides(sortedItems);
      } else {
        setSlides(items);
      }
      // console.log({ items, sortedItems })
    }
  }, [0]);

  const handleItemClick = (event, item) => {
    if (!isScrolling.current) { // Only trigger click if not scrolling
      event.stopPropagation();
      handleQuickbiteClick(isMenu ? item?.item_name : item);
    }
  };
  console.log({ slides })

  return slides.length > 0 && (
      <div className='slider-container'>
        <Slider className="mb-3" {...settings}>
          {isMenu && (
            <div className="" key="all">
              <Link to="#" className="dishname" onClick={(event) => handleItemClick(event, {item_name: 'All'})}>
                <span>
                  <Image src={'/Images/healthy.png'} />
                </span>
                <h4>All</h4>
              </Link>
            </div>
          )}
          {slides.map((item, index) => (
            <div className="" key={item.item_id}>
              <Link to="#" className="dishname" onClick={(event) => handleItemClick(event, item)}>
                <span>
                  <Image src={item.url || '/Images/healthy.png'} />
                </span>
                <h4>{item.item_name}</h4>
              </Link>
            </div>
          ))}
        </Slider>
        </div>
  );
};

export default Carousel;
