import React from 'react';
import './Search.css';
import { Image, Dropdown, DropdownButton } from 'react-bootstrap';
function Search({ selectedOption, handleCategoryClick, handleSearchchnage, toggleImage, isImageShown }) {
    // Function to determine the selected image and title
    const getSelectedImage = () => {
        switch (selectedOption) {
            case 'V':
                return <Image src="/Images/veg.svg" alt="Veg" className="dropdown-image" />;
            case 'N':
                return <Image src="/Images/nonveg.svg" alt="Non-Veg" className="dropdown-image" />;
            case 'E':
                return <Image src="/Images/egg.svg" alt="Egg" className="dropdown-image" />;
            default:
                return null;
        }
    };
    return (
        <div className={`searchmain`}>
            <i><Image src='/Images/searchicon.svg' alt="Search Icon" /></i>
            <input type="search" placeholder='Search' onChange={handleSearchchnage} />
            <DropdownButton
                id="dropdown-basic-button"
                title={
                    selectedOption ? (
                        <div className="selected-option-title">
                            {getSelectedImage()}
                        </div>
                    ) : "Select Category"
                }
                className="dropdown-toggle-button-"
            >
                <Dropdown.Item onClick={() => handleCategoryClick('V')}>
                    <Image src="/Images/veg.svg" alt="Veg" className="dropdown-image " />
                </Dropdown.Item>
                <Dropdown.Item onClick={() => handleCategoryClick('N')}>
                    <Image src="/Images/nonveg.svg" alt="Non-Veg" className="dropdown-image " />
                </Dropdown.Item>
                <Dropdown.Item onClick={() => handleCategoryClick('E')}>
                    <Image src="/Images/egg.svg" alt="Egg" className="dropdown-image " />
                </Dropdown.Item>
            </DropdownButton>
        </div>
    );
}
export default Search;