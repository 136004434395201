export const formatIndianCurrency = (number) => {
    if(number) {
        // Convert the number to a string
        let numStr = number.toString();
        
        // Use a regular expression to insert commas
        const regex = /(\d)(?=(\d\d)+\d$)/g;
        const formattedNumber = numStr.replace(regex, '$1,');
    
        return formattedNumber;
    } else {
        return 0
    }
}