import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import './SignUp.css';
import { Button, Form, Image } from 'react-bootstrap';
import { userInfo } from './action/action';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { setScannedTable, setUserRegistered } from '../HomePage/Tableslice/Tableslice';

function SignUp() {
    const { table } = useSelector((state) => state?.table);
    const navigate = useNavigate();
    const [name, setName] = useState('');
    const [phone, setPhone] = useState();
    const dispatch = useDispatch();
    const {tableId} = useParams();
    
    useEffect(() => {
        if(tableId) {
            dispatch(setScannedTable(tableId))
        }
    }, [tableId])
    
    const handleregisterform = async (e) => {
        e.preventDefault();
        
        if (name.length < 2 || name.length > 20) {
            toast.error('Name must be between 2 and 20 characters long.');
            return;
        }
        if (phone.length !== 10 || !/^\d+$/.test(phone)) {
            toast.error('Phone number must be exactly 10 digits.');
            return;
        }
        const header = {
            name: name,
            phone: Number(phone),
            order_id: table?.order_id,
        }; 
        try {
            const res = await userInfo(header);
            // toast.success(res.data.message)
            if (res.data) {
                dispatch(setUserRegistered(true))
                navigate(`/cart/${tableId}`);
            }
        } catch (error) {
            toast.error('Error registering user:', error);
        }
    };

    return (
        <>
            <section className='signupmain'>
                <div className="container">
                    <div className="tabledetail p-0">
                        <div className="bggreen">
                            <i><Image src='/Images/foodimg.png'></Image></i>
                            <span><Image src='/Images/signupicon.svg'></Image></span>
                            <h3>Where Turkish <br /> Delights Meet <br /> Mediterranean Nights</h3>
                        </div>
                        <div className="signupform">
                            <h4>Sign up</h4>
                            <Form onSubmit={handleregisterform}>
                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Label>Name</Form.Label>
                                    <Form.Control type="text" placeholder="Enter your name" value={name} onChange={(e) => setName(e.target.value)} required />
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="formBasicPassword">
                                    <Form.Label>Whatsapp Number</Form.Label>
                                    <div className="mobile">
                                        <span>+91</span>
                                        <Form.Control type="text" placeholder="(000) 000-0000" value={phone} onChange={(e) => setPhone(e.target.value)} required />
                                    </div>
                                </Form.Group>
                                {/* <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Label>DOB</Form.Label>
                                    <Form.Control type="date" value={dob} onChange={(e) => setDOB(e.target.value)} required />
                                </Form.Group> */}
                                <Button variant="primary" type="submit" className='btn-green'>
                                    Finish your order
                                </Button>
                            </Form>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default SignUp;
